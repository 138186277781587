body {
    /* margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; */
    overflow: scroll;
  }
  
  /* Styles for the app container to apply background changes */
  .app-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.3s ease; /* Smooth transition when changing background */
  }
  
  /* White Mode Styles */
  .white-mode {
    background: linear-gradient(135deg, #ffffff, #cee5f788, #e5f3fff2, #cee5f788, #c5ddf09a);
  }
  
  .white-mode .container {
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
    color: #001F54;
  }
  
  /* Dark Mode Styles */
  .dark-mode {
    background: linear-gradient(135deg, #2e2e2e, #201f1f, #1c1b1b, #1a1a1a, #202020);
  }
  
  .dark-mode .container {
    background: rgba(55, 54, 54, 0.818); /* Semi-transparent dark gray */
    color: #e5f3fff2;
  }
  
  /* Shared Styles for the Container */
  .container {
    /* width: 16cm;
    height: 16cm; */
    backdrop-filter: blur(10px); /* Frosted glass effect */
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px;
    text-align: center;
  }
  
  .toggle-button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    cursor: pointer;
  }
  
  .toggle-button:hover {
    background-color: #555;
  }
  